import React, { useState } from 'react';
import { TextField, Box, Card, CardActionArea, CardContent, CardMedia, Typography, Button } from '@mui/material';
import {games} from '../data/games.js'; 
import { Link } from 'react-router-dom';

export default function SearchBar () {
  const [searchTerm, setSearchTerm] = useState('');
  const [showResults, setShowResults] = useState(false);

  const filteredGames = games.filter((game) =>
    game.title.toLowerCase().includes(searchTerm.toLowerCase())
  ).slice(0, 5); 

  const handleBlur = () => {
    // Set a timeout to delay the execution, giving time for the link click to work
    setTimeout(() => {
      setShowResults(false);
      setSearchTerm(''); // Clear the search term
    }, 100);
  };

  return (
    <Box>
      <TextField
        label="Search game.."
        variant="outlined"
        size="small"
        position="relative"
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#ffffff", // changes the border color
            },
            "&:hover fieldset": {
              borderColor: "#ffffff", // changes the border color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "#ffffff", // changes the border color on focus
            }
          },
          "& .MuiInputBase-input": {
            color: "#ffffff", // changes the text color
          },
          "& .MuiInputLabel-root": {
            color: "#ffffff", // changes the label color
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#ffffff", // ensures the label color remains white when focused
          },
        }}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setShowResults(e.target.value.length > 0);
        }}
        onBlur={handleBlur}
        fullWidth
      />
      {showResults && filteredGames.length > 0 && (
        <Box sx={{ position: 'absolute', zIndex: 1, mt: 1, backgroundColor: 'primary.main600', maxWidth: '100%', border: "1px solid", borderColor: "primary.main600", borderRadius: "5px" }}>
          {filteredGames.map((game) => (
            <Card key={game.id} sx={{ display: 'flex', mb: 1, height: "50px", backgroundColor: "primary.main500" }}>
              <CardActionArea component={Link} to={`/game/${game.id}`} sx={{ display: 'flex' }}>
                <CardMedia
                  component="img"
                  sx={{ width: 70, objectFit: "contain" }}
                  image={game.image}
                  alt={game.title}
              />
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography variant="h6">
                    {game.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
}
