import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";


const DisclaimerModal = () => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      setOpen(true);
      localStorage.setItem("hasVisited", "true");
    }
  }, []);


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="modal"
        maxWidth="lg"
        scroll="paper"
        textAlign="center"
      >
        <DialogTitle fontSize="2rem" textAlign="center">Are you over 18 years old?</DialogTitle>
        <DialogContent>
          <Typography variant="body1" textAlign="center" paragraph>
          This website is intended for persons over 18 years of age <br/> and is for entertainment purposes only.   
          </Typography>
         
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Yes
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose}>
              No
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DisclaimerModal;
