// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";
import Form from "./Form";

function CTA() {
  return (
    <Box sx={{ backgroundColor: "primary.main500" }} id="about">
      
<Container maxWidth="lg">
        {/* Text Section */}
        <Grow in={true} timeout={1200} appear={true}>
          <Box mt={3}>
          <Typography variant="h4" gutterBottom>
          Welcome to cashrollbet.com, your ultimate destination for pure
          entertainment. 
        </Typography>
        <Typography variant="h5" gutterBottom>Our goal is to create a delightful oasis where the thrill of playing is the prize itself. <br/> We are committed to fostering a vibrant space that celebrates the joy of leisure and provides a break from the daily grind.</Typography>
        <Typography variant="body1">
          Explore our vast array of games, designed to cater to diverse tastes and interests. Whether you seek the thrill of solving intricate puzzles, the excitement of navigating fantastical worlds in role-playing games, or the relaxation of engaging in simple, carefree activities, we have it all. Our ever-evolving selection keeps the portal dynamic and engaging, ensuring you always have something new to explore. Here, games are more than just a way to pass time—they are a journey into worlds of adventure, creativity, and endless fun.
        </Typography>
          </Box>
        </Grow>
        <Form/>
      </Container>
    </Box>
  );
}

export default CTA;
